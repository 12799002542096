import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar2 from "../..//Navbar/Navbar";
import Footer2 from "../../footer/Footer";
import "./Chose.css";

function BasicExample() {
  return (
    <>
      <Navbar2 />

      <Container style={{ marginBottom: "100px" }}>
        <hr />

        <Row id="rowchoose">
          <Col xs={12} md={6} lg={6} xl={6} id="colo1" className="button-container" style={{ width: "270px" }}>
            <Link to="/importplate">
              <Button variant="success" style={{ width: "200px" }}>
                Inserisci una piatto{" "}
              </Button>
            </Link>
          </Col>

          <Col xs={12} md={6} lg={6} xl={6} id="colo1" style={{ width: "270px" }} className="button-container">
            <Link to="/importphoto">
              <Button variant="success" style={{ width: "250px" }}>
                Inserisci una foto alla galleria
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
      <Footer2 />
    </>
  );
}

export default BasicExample;
