import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row, Modal } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import Navbar2 from "../../Navbar2/Navbar";
import Footer from "../../footer/Footer";

function FormExample() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [databaseData, setDatabaseData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const schema = yup.object().shape({
    titolo: yup.string().required("Il nome è richiesto"),
    descrizione: yup.string().required("Il cognome è richiesto"),
    file: yup.mixed().required("Il file è richiesto"),
  });

  const toggleModal = () => setShowModal(!showModal);

  const fetchDataFromDatabase = async () => {
    try {
      const response = await fetch("scattu/import.php");
      if (response.ok) {
        const jsonData = await response.json();
        setDatabaseData(jsonData);
      } else {
        console.error("Errore nel recupero dei dati dal database:", response.statusText);
      }
    } catch (error) {
      console.error("Errore nel recupero dei dati dal database:", error);
    }
  };

  useEffect(() => {
    fetchDataFromDatabase();
  }, []);

  const submitForm = async (values) => {
    try {
      const formData = new FormData();
      formData.append("titolo", values.titolo);
      formData.append("descrizione", values.descrizione);
      formData.append("traduzione", values.traduzione);
      formData.append("file", values.file);

      const response = await fetch("scattu/insertimg.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const jsonData = await response.json();
        setSubmittedData(jsonData);
        setFormSubmitted(true);
        fetchDataFromDatabase();
        toggleModal();
      } else {
        console.error("Impossibile inviare il modulo:", response.statusText);
      }
    } catch (error) {
      console.error("Impossibile inviare il modulo:", error);
    }
  };

  const deleteDataFromDatabase = async (id) => {
    try {
      const response = await fetch(`scattu/deleteData.php?id=${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        fetchDataFromDatabase();
      } else {
        console.error("Impossibile eliminare i dati:", response.statusText);
      }
    } catch (error) {
      console.error("Impossibile eliminare i dati:", error);
    }
  };

  const handleModalClose = () => {
    toggleModal();
    window.location.reload();
  };

  return (
    <>
      <Navbar2 />
      <Container>
        <div>
          <div className="logocandidati d-flex">
            <img src="/img/iknos2.png" alt="" style={{ width: "108px", marginTop: "34px", marginBottom: "20px" }} />
          </div>
          <Formik
            validationSchema={schema}
            onSubmit={(values) => {
              submitForm(values);
            }}
            initialValues={{
              titolo: "",
              descrizione: "",
              traduzione: "",
              file: null,
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4" controlId="validationFormik101" className="position-relative">
                    <Form.Label>Titolo</Form.Label>
                    <Form.Control
                      placeholder="Inserisci il titolo"
                      type="text"
                      name="titolo"
                      value={values.titolo}
                      onChange={handleChange}
                      isValid={touched.titolo && !errors.titolo}
                      isInvalid={touched.titolo && !!errors.titolo}
                      className="mb-3"
                    />
                    <Form.Control.Feedback type="invalid">{errors.titolo}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationFormik102" className="position-relative">
                    <Form.Label>Descrizione</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Inserisci la descrizione"
                      name="descrizione"
                      value={values.descrizione}
                      onChange={handleChange}
                      isValid={touched.descrizione && !errors.descrizione}
                      isInvalid={touched.descrizione && !!errors.descrizione}
                      className="mb-3"
                    />
                    <Form.Control.Feedback type="invalid">{errors.descrizione}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationFormik103" className="position-relative">
                    <Form.Label>Traduzione</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Inserisci la traduzione"
                      name="traduzione"
                      value={values.traduzione}
                      onChange={handleChange}
                      isValid={touched.traduzione && !errors.traduzione}
                      isInvalid={touched.traduzione && !!errors.traduzione}
                      className="mb-3"
                    />
                    <Form.Control.Feedback type="invalid">{errors.traduzione}</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Form.Group className="position-relative mb-3 mt-2">
                  <Form.Label>File</Form.Label>
                  <Form.Control
                    type="file"
                    required
                    name="file"
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    isInvalid={!!errors.file}
                    className="mb-3"
                  />
                  <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" style={{ display: "flex", marginInline: "auto", marginBottom: "70px" }}>
                  Invia
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <div>
          <h3 className="mb-5">Dati presenti nel database:</h3>
          <Row xs={1} md={2} lg={3} className="g-4">
            {databaseData.map((dataItem) => (
              <Col key={dataItem.id} id="cardmanuale">
                <Card
                  style={{
                    marginBottom: "20px",
                    width: "18rem",
                    height: "100%",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={`/scattu/img/${dataItem.file}`}
                    style={{ width: "100%", height: "15rem", objectFit: "cover" }}
                  />
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <div className="text-center">
                      <Card.Title>{dataItem.titolo}</Card.Title>
                      <Card.Text>Descrizione: {dataItem.descrizione}</Card.Text>
                      <Card.Text>Traduzione: {dataItem.traduzione}</Card.Text>
                    </div>
                    <Button variant="danger" onClick={() => deleteDataFromDatabase(dataItem.id)}>
                      Elimina
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      <Footer />
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Foto Inserita con successo</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={handleModalClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FormExample;
