import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Card.css";

function SimpleCards() {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
          <div className="card-container">
            <Link to="/menu">
              <img src="./img/restaurant/tagliatelle.png" alt="Tagliatelle" />
              <div className="overlay">Ristorante</div>
            </Link>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
          <div className="card-container">
            <Link to="/menu">
              <img src="./img/pizze/mortadella.png" alt="Mortadella" />
              <div className="overlay">Pizzeria</div>
            </Link>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
          <div className="card-container">
            <Link to="/diner">
              <img src="./img/American/american.jpg" alt="American" />
              <div className="overlay">American Diner</div>
            </Link>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
          <div className="card-container">
            <Link to="/homeap">
              <img src="../img/appartamenti/camera.jpeg" alt="Gelati" />
              <div className="overlay">Appartamenti</div>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SimpleCards;
