import React, { useState, useEffect } from "react";
import SmallVideo from "./SmallVideo";
import BigVideo from "./BigVideo";
import "./Video.css";

function Video() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 450);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 750);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div className="video-container">{isSmallScreen ? <SmallVideo /> : <BigVideo />}</div>;
}

export default Video;
