import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, Container, Form, Row, ListGroup } from "react-bootstrap";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import Navbar1 from "../../Navbar2/Navbar";

function Backoffice() {
  const [submittedData, setSubmittedData] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const isAuthenticated = Cookies.get("PHPSESSID");

  const schema = yup.object().shape({
    nome: yup.string().required(),
    prezzo: yup.string().required(),
    traduzione: yup.string().required(),
    tipoPiatto: yup.string().required(),
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const PHPSESSID = Cookies.get("PHPSESSID");
        if (!PHPSESSID) {
          return; // Non autenticato, non proseguire con il fetch
        }

        const response = await fetch("scattu/importfuorimenu.php", {
          headers: {
            Cookie: `PHPSESSID=${PHPSESSID}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log("Dati ottenuti dal database:", data);
          setMenuData(data);
        } else {
          console.error("Errore durante il recupero dei dati dal database:", response.statusText);
        }
      } catch (error) {
        console.error("Errore durante la comunicazione con il server:", error);
      }
    }

    fetchData();
  }, []);

  const handleSubmit = async (values, actions) => {
    try {
      const PHPSESSID = Cookies.get("PHPSESSID");
      const formData = new FormData();
      formData.append("nome", values.nome);
      formData.append("prezzo", values.prezzo);
      formData.append("traduzione", values.traduzione);
      formData.append("tipoPiatto", values.tipoPiatto);

      const response = await fetch("/scattu/fuorimenu.php", {
        method: "POST",
        body: formData,
        headers: {
          Cookie: `PHPSESSID=${PHPSESSID}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Dati inseriti con successo:", data);
        setSubmittedData(data);
        actions.resetForm();

        setMenuData((prevMenuData) => [...prevMenuData, data]);
      } else {
        console.error("Errore durante l'inserimento dei dati:", response.statusText);
      }
    } catch (error) {
      console.error("Errore durante la comunicazione con il server:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const PHPSESSID = Cookies.get("PHPSESSID");
      const response = await fetch(`/scattu/importfuorimenu.php?id=${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Cookie: `PHPSESSID=${PHPSESSID}`,
        },
      });
      if (response.ok) {
        const newData = menuData.filter((item) => item.id !== id);
        setMenuData(newData);
        console.log("Riga eliminata con successo");
      } else {
        console.error("Errore durante l'eliminazione della riga:", response.statusText);
      }
    } catch (error) {
      console.error("Errore durante la comunicazione con il server:", error);
    }
  };

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Navbar1 />
      <Container id="backform" className="d-flex justify-content-center align-items-center flex-column">
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            nome: "",
            prezzo: "",
            traduzione: "",
            tipoPiatto: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit} style={{ width: "100%" }} method="POST">
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationFormik105" className="position-relative">
                  <Form.Label>Tipo di Piatto</Form.Label>
                  <Form.Control
                    as="select"
                    name="tipoPiatto"
                    value={values.tipoPiatto}
                    onChange={handleChange}
                    isInvalid={!!errors.tipoPiatto}
                  >
                    <option value="">Seleziona il tipo di piatto</option>
                    <option value="antipasto">Antipasto</option>
                    <option value="primo">Primo</option>
                    <option value="secondo">Secondo</option>
                    <option value="dolce">Dolce</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.tipoPiatto}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationFormik101" className="position-relative">
                  <Form.Label>Nome Del Piatto</Form.Label>
                  <Form.Control
                    placeholder="Nome del piatto"
                    type="text"
                    name="nome"
                    value={values.nome}
                    onChange={handleChange}
                    isValid={touched.nome && !errors.nome}
                  />
                  <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationFormik104" className="position-relative">
                  <Form.Label>Traduzione</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Traduzione"
                    name="traduzione"
                    value={values.traduzione}
                    onChange={handleChange}
                    isInvalid={!!errors.traduzione}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.traduzione}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationFormik103" className="position-relative">
                  <Form.Label>Prezzo</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Prezzo"
                    name="prezzo"
                    value={values.prezzo}
                    onChange={handleChange}
                    isInvalid={!!errors.prezzo}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.prezzo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Button type="submit">Inserisci Piatto</Button>
            </Form>
          )}
        </Formik>
      </Container>

      <Container className="mt-5">
        <h1 className="mt-5 text-center">Dati dal Database</h1>
        {menuData.length === 0 ? (
          <p className="text-center">Nessun dato disponibile</p>
        ) : (
          <ListGroup>
            {/* Render menu data with delete buttons */}
            <ListGroup.Item>
              <h3 className="mb-3 text-bg-success">Antipasti</h3>
              {menuData
                .filter((item) => item.tipoPiatto === "antipasto")
                .map((item, index) => (
                  <div key={index} className="mb-3">
                    <br></br>
                    <strong>Nome del piatto:</strong> {item.nome}, <br />
                    <strong>Prezzo:€</strong> {item.prezzo}, <br />
                    <strong>Traduzione:</strong> {item.traduzione}
                    <br></br>
                    <br></br>
                    <Button variant="danger" onClick={() => handleDelete(item.id)} className="ms-2">
                      Elimina
                    </Button>
                    <hr></hr>
                  </div>
                ))}
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="mb-3 text-bg-success">Primi</h3>
              {menuData
                .filter((item) => item.tipoPiatto === "primo")
                .map((item, index) => (
                  <div key={index} className="mb-3">
                    <br></br>
                    <strong>Nome del piatto:</strong> {item.nome}, <br />
                    <strong>Prezzo:€</strong> {item.prezzo} <br />
                    <strong>Traduzione:</strong> {item.traduzione}
                    <br></br>
                    <br></br>
                    <Button variant="danger" onClick={() => handleDelete(item.id)} className="ms-2">
                      Elimina
                    </Button>
                    <hr></hr>
                  </div>
                ))}
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="mb-3 text-bg-success">Secondi</h3>
              {menuData
                .filter((item) => item.tipoPiatto === "secondo")
                .map((item, index) => (
                  <div key={index} className="mb-3 ">
                    <br></br>
                    <strong>Nome del piatto:</strong> {item.nome}, <br />
                    <strong>Prezzo:€</strong> {item.prezzo}, <br />
                    <strong>Traduzione:</strong> {item.traduzione}
                    <br></br>
                    <br></br>
                    <Button variant="danger" onClick={() => handleDelete(item.id)} className="ms-2">
                      Elimina
                    </Button>
                    <hr></hr>
                  </div>
                ))}
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="mb-3 text-bg-success">Dolci</h3>
              {menuData
                .filter((item) => item.tipoPiatto === "dolce")
                .map((item, index) => (
                  <div key={index} className="mb-3">
                    <br></br>
                    <strong>Nome del piatto:</strong> {item.nome}, <br />
                    <strong>Traduzione:</strong> {item.traduzione}
                    <strong>Prezzo:€ </strong> {item.prezzo}, <br />
                    <br></br>
                    <br></br>
                    <Button variant="danger" onClick={() => handleDelete(item.id)} className="ms-2">
                      Elimina
                    </Button>
                    <hr></hr>
                  </div>
                ))}
            </ListGroup.Item>
          </ListGroup>
        )}
      </Container>
    </div>
  );
}

export default Backoffice;
