import React from "react";
import { FaFacebookF, FaInstagram, FaGoogle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "./Footer.css";

const Footer2 = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer-dark" id="footerxx">
      <Link to="/">
        <img src="./img/logo/logoscattu.png" alt="" style={{ width: "60px" }} />
      </Link>
      <Link to="/diner">
        <img src="./img/logo/burger.png" alt="" style={{ width: "60px", marginLeft: "25px" }} />
      </Link>
      <Link to="/homeap">
        <img src="./img/logo/app.png" alt="" style={{ width: "60px", marginLeft: "25px" }} />
      </Link>

      <footer>
        <div className="container">
          <Row>
            <Col xs="12" md="4" className="footer-column">
              <h3 id="titlefooter">Servizi</h3>
              <ul>
                <li>Pizzeria con forno a legna</li>
                <li>Ristorante</li>
                <li>America Diner</li>
              </ul>
            </Col>
            <Col xs="12" md="4" className="footer-column">
              <h3 id="titlefooter">Contatti</h3>
              <ul>
                <li>
                  <a href="mailto:dascattu@hotmail.com">dascattu@hotmail.com</a>
                </li>
                <li>
                  <a href="tel:0782 695472">0782 695472</a>
                </li>
                <li>
                  <a href="https://maps.app.goo.gl/NfwEsgRLnkVJTcAJ6">Via Monsignor Virgilio, 36, 08048 Tortolì (NU)</a>
                </li>
              </ul>
            </Col>
            <Col xs="12" md="4" className="footer-column">
              {" "}
              <div className="admin mb-4">
                <Link to="/admin" className="foot" onClick={scrollToTop}>
                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/ios-filled/50/FFFFFF/admin-settings-male.png"
                    alt="admin-settings-male"
                    className="mb-2"
                  />
                </Link>
              </div>
              <span>Log-in</span>
            </Col>
          </Row>
          <hr />
          <div className="social">
            <a href="https://www.facebook.com/PizzeriaDaScattu/?locale=it_IT" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/da_scattu/?hl=it" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://maps.app.goo.gl/NfwEsgRLnkVJTcAJ6" target="_blank" rel="noopener noreferrer">
              <FaGoogle />
            </a>
          </div>
        </div>

        <a href="https://marcopala-portfolio.web.app/">
          {" "}
          <img src="./white.png" alt="" style={{ width: "170px" }} id="logomio" />
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/17608890"
          class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe"
          title="Privacy Policy "
        >
          Privacy Policy
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/17608890/cookie-policy"
          class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe"
          title="Cookie Policy "
        >
          Cookie Policy
        </a>
      </footer>
    </div>
  );
};

export default Footer2;
