import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../Navbar/Navbar";
import Video from "../Video/Video";
import "./Home.css";
import Card from "../Card/Card";
import Footer from "../footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FadeInSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 1.5, delay }}
    >
      {children}
    </motion.div>
  );
};

const Home = () => {
  return (
    <>
      <Navbar />
      <Video />
      <Container>
        <FadeInSection delay={0.1}>
          <Row id="roow">
            <div id="primoblocco">
              <h1 style={{ color: "#D69E79" }}>Filosofia</h1>
              <p>
                Cucina non è soltanto nutrire il corpo, ma anche nutrire l'anima con l'arte, la passione e l'esperienza
                culinaria: questa è la filosofia che guida ogni piatto nel nostro ristorante.
              </p>
            </div>
            <hr />
          </Row>
        </FadeInSection>
        <FadeInSection delay={0.2}>
          <Row id="roow">
            <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 1 }} id="col">
              <img
                src="./img/pizze/pizzanera.png"
                alt="Pizza Nera"
                style={{ width: "100%", marginTop: "90px" }}
                id="imghome"
              />
            </Col>
            <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 2 }} id="col">
              <div id="secondoblocco">
                <h1 style={{ color: "#A5434B" }} id="h1pizzeria">
                  Pizzeria
                </h1>
                <p>* * * * *</p>
                <p>
                  Dal 1958, la prima pizza in Ogliastra rappresenta una tradizione culinaria che ha attraversato
                  generazioni, portando con sé il sapore autentico della Sardegna. Ogni morso racconta una storia di
                  passione, dedizione e maestria, unendo ingredienti freschi e genuini con la maestria artigianale dei
                  pizzaioli locali. Questa pizza non è solo un piatto, ma un simbolo di identità culturale e amore per
                  la buona cucina, che continua a deliziare residenti e visitatori con la sua inconfondibile fragranza e
                  sapore unico.
                </p>
                <hr />
              </div>
            </Col>
          </Row>
        </FadeInSection>
        <FadeInSection delay={0.3}>
          <Row>
            <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }} id="col">
              <img
                src="./img/restaurant/ravioli.png"
                alt="Pescatora"
                style={{ width: "100%", marginTop: "130px" }}
                id="imghome"
              />
            </Col>
            <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }} id="col">
              <div id="secondoblocco">
                <h1 style={{ color: "#278280" }}>Ristorante</h1>
                <p>* * * * *</p>
                <p>
                  Il "Ristorante Tipico Sardo" è un luogo dove l'autenticità e la tradizione della cucina sarda si
                  fondono per offrire un'esperienza gastronomica unica. Situato nel cuore della città, questo ristorante
                  accoglie i suoi ospiti in un'atmosfera calda e familiare, dove ogni dettaglio, dall'arredamento
                  rustico all'accoglienza calorosa, richiama le radici e la cultura della Sardegna.
                </p>
                <hr />
              </div>
            </Col>
          </Row>
        </FadeInSection>
        <FadeInSection delay={0.4}>
          <Row id="roow">
            <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 1 }} id="col">
              <img
                src="./img/American/american.jpg"
                alt="I nostri appartamenti"
                style={{ width: "100%", marginTop: "130px" }}
                id="imghome"
              />
            </Col>
            <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 2 }} id="col">
              <div id="secondoblocco">
                <h1 style={{ color: "#333333" }}>American Diner</h1>
                <p>* * * * *</p>
                <p>
                  Al piano di sopra invece, venite catapultati in un angolo di America nel cuore della Sardegna il
                  nostro American Diner. Situato in una posizione strategica della città, questo diner offre
                  un'esperienza unica che combina l'autentico stile americano con l'ospitalità sarda. L'atmosfera è
                  quella di un classico diner anni '50, con arredi colorati, jukebox, e il profumo invitante dei piatti
                  tipici americani che si diffonde nell'aria.
                </p>
              </div>
            </Col>
          </Row>
        </FadeInSection>
        <hr />
        <FadeInSection delay={0.5}>
          <h1 id="insmenu">Da noi potrai trovare</h1>
        </FadeInSection>
        <FadeInSection delay={0.6}>
          <Card />
        </FadeInSection>
        <FadeInSection delay={0.7}>
          <Row>
            <Col xs={12} className="text-center">
              <Link to="/menu">
                <Button variant="success" className="special-button mt-5 mb-5">
                  Vai ai Menu
                </Button>
              </Link>
            </Col>
          </Row>
        </FadeInSection>
        <br></br>
        <br></br>
        <br></br>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
