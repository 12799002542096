// Login.js
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer2 from "../../footer/Footer";
import Navbar from "../../Navbar/Navbar";
function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError("Inserisci un username e una password validi.");
      return;
    }

    try {
      const response = await fetch("/scattu/login.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ action: "login", username, password }),
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        setError("");
        alert("Login eseguito con successo!");
        window.location.href = "/choose";
      }
    } catch (error) {
      setError("Errore durante la comunicazione con il server.");
      console.log("errore jsx");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mt-5 d-flex justify-content-center">
        <div
          style={{
            width: "400px",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            marginBottom: "100px",
          }}
        >
          <h2 className="mb-3">Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Accedi
            </button>
            {error && <div className="alert alert-danger mt-3">{error}</div>}
          </form>
        </div>
      </div>
      <Footer2></Footer2>
    </>
  );
}

export default Login;
