import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Modal from "react-bootstrap/Modal";
import "./Navbar.css";

function NavScrollExample() {
  const [showModal, setShowModal] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);

  const handleShowModal = () => {
    setShowModal(true);
    setShowNavbar(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowNavbar(true);
  };

  return (
    <>
      {!showModal && (
        <Navbar
          expand="lg"
          id="position-navbar"
          style={{ display: showNavbar ? "block" : "none", marginInline: "auto" }}
        >
          <Container fluid>
            <Navbar.Toggle aria-controls="navbarScroll" onClick={handleShowModal} />
            <Navbar.Collapse id="navbarScroll">
              <Nav style={{ maxHeight: "100px", gap: "10px", marginInline: "auto", marginTop: "30px" }}>
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/diner">
                  American Diner
                </Nav.Link>
                <Nav.Link as={Link} to="/homeap">
                  Appartamenti
                </Nav.Link>
                <Nav.Link as={Link} to="/menu">
                  Menù
                </Nav.Link>
                <Nav.Link as={Link} to="/gallery">
                  Galleria
                </Nav.Link>
                <Nav.Link as={Link} to="/candidati">
                  Lavora con noi
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton> Da Scattu</Modal.Header>
        <Modal.Body>
          <Nav className="flex-column">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/diner">
              American Diner
            </Nav.Link>
            <Nav.Link as={Link} to="/menu">
              Menù
            </Nav.Link>
            <Nav.Link as={Link} to="/gallery">
              Galleria
            </Nav.Link>
            <Nav.Link as={Link} to="/candidati">
              Lavora con noi
            </Nav.Link>
          </Nav>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NavScrollExample;
