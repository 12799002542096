import React, { useState, useEffect } from "react";

import { Container, ListGroup } from "react-bootstrap";

import Navbar1 from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import "./Fuorimenu.css";
function Backoffice() {
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch("scattu/importfuorimenu.php");
        if (response.ok) {
          const data = await response.json();

          setMenuData(data);
        } else {
          console.error("Errore durante il recupero dei dati dal database:", response.statusText);
        }
      } catch (error) {
        console.error("Errore durante la comunicazione con il server:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Navbar1 />

      <Container className="mt-5">
        <h1 className="mt-5 mb-5 text-center" id="fuorimenu">
          Fuori Menù
        </h1>
        <p style={{ fontFamily: "cursive", fontSize: "10px", textAlign: "center" }}>
          {" "}
          Qui troverai aggiornati quotidianamente i nostri fuori menu che potrai trovare nel nostro locale{" "}
        </p>
        <ListGroup style={{ maxWidth: "500px", marginInline: "auto", textAlign: "center" }}>
          {/* Render menu data with delete buttons */}
          <ListGroup.Item>
            <h3 className="mb-3 " style={{ background: "#baf4b5" }}>
              Antipasti
            </h3>
            {menuData
              .filter((item) => item.tipoPiatto === "antipasto")
              .map((item, index) => (
                <div key={index} className="mb-3" id="box40">
                  <hr></hr>
                  <p className="pplate" style={{ marginBottom: "0px", whiteSpace: "pre-wrap" }}>
                    {item.nome}
                  </p>
                  <p
                    className="translate"
                    style={{
                      fontFamily: '"Cormorant Garamond", serif',
                      fontWeight: 300,
                      fontStyle: "italic",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {item.traduzione}
                  </p>
                  <p> {item.prezzo + "€"}</p> <br />
                </div>
              ))}
          </ListGroup.Item>
          <ListGroup.Item>
            <h3 className="mb-3 " style={{ background: "#baf4b5" }}>
              Primi
            </h3>
            {menuData
              .filter((item) => item.tipoPiatto === "primo")
              .map((item, index) => (
                <div key={index} className="mb-3" id="box40">
                  <hr></hr>
                  <p className="pplate">{item.nome}</p>
                  <p
                    className="translate"
                    style={{
                      fontFamily: '"Cormorant Garamond", serif',
                      fontWeight: 300,
                      fontStyle: "italic",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {item.traduzione}
                  </p>
                  <p> {item.prezzo + "€"} </p> <br />
                </div>
              ))}
          </ListGroup.Item>
          <ListGroup.Item>
            <h3 className="mb-3" style={{ background: "#baf4b5" }}>
              Secondi
            </h3>
            {menuData
              .filter((item) => item.tipoPiatto === "secondo")
              .map((item, index) => (
                <div key={index} className="mb-3 " id="box40">
                  <hr></hr>
                  <p className="pplate" style={{ whiteSpace: "pre-wrap" }}>
                    {" "}
                    {item.nome}
                  </p>{" "}
                  <p
                    className="translate"
                    style={{
                      fontFamily: '"Cormorant Garamond", serif',
                      fontWeight: 300,
                      fontStyle: "italic",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {item.traduzione}
                  </p>
                  <p>{item.prezzo + "€"}</p> <br />
                </div>
              ))}
          </ListGroup.Item>
          <ListGroup.Item style={{ marginBottom: "60px" }}>
            <h3 className="mb-3 " style={{ background: "#baf4b5" }}>
              Dolci
            </h3>
            {menuData
              .filter((item) => item.tipoPiatto === "dolce")
              .map((item, index) => (
                <div key={index} className="mb-3" id="box40">
                  <hr></hr>
                  <p className="pplate">{item.nome}</p>
                  <p
                    className="translate"
                    style={{ fontFamily: '"Cormorant Garamond", serif', fontWeight: 300, fontStyle: "italic" }}
                  >
                    {item.traduzione}
                  </p>
                  <p>{item.prezzo + "€"}</p> <br />
                </div>
              ))}
          </ListGroup.Item>
        </ListGroup>
      </Container>
      <Footer></Footer>
    </div>
  );
}

export default Backoffice;
