import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Carosello.css";

function IndividualIntervalsExample() {
  return (
    <Carousel interval={4000} indicators={false} controls={false} style={{ marginBottom: "50px" }}>
      <Carousel.Item>
        <img className="d-block w-100 large-screen" src="./img/American/carosello1.jpg" alt="First slide" />
        <img className="d-block w-100 small-screen" src="./img/American/carosello10.jpg" alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100 large-screen" src="./img/American/carosello2.jpg" alt="Second slide" />
        <img className="d-block w-100 small-screen" src="./img/American/carosello11.jpg" alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100 large-screen" src="./img/American/carosello3.jpg" alt="Third slide" />
        <img className="d-block w-100 small-screen" src="./img/American/carosello12.jpg" alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100 large-screen" src="./img/American/carosello4.jpg" alt="Fourth slide" />
        <img className="d-block w-100 small-screen" src="./img/American/carosello13.jpg" alt="Fourth slide" />
      </Carousel.Item>
    </Carousel>
  );
}

export default IndividualIntervalsExample;
