import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Navbar from "../Navbardinner/Navbar";
import { Link } from "react-router-dom";
import "./Home.css";
import "./query/query.css";
import Footer from "../footer/Footer";
import Videodinner from "./video/Videodinner";
import Carousel from "./Carosellodinner/Carosello";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FadeInSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 1.5, delay }}
    >
      {children}
    </motion.div>
  );
};

const Home = () => {
  return (
    <>
      <Navbar />
      <Videodinner />
      <Container fluid>
        <FadeInSection delay={0.1}>
          <Row>
            <Col xs={12} md={12}>
              <div className="titolo">
                <h1 style={{ color: "#D69E79" }} id="titolodin">
                  AMERICAN DINER
                </h1>
                <br />
                <br />
                <p id="paragrafodinner">
                  Vogliamo farvi rivivere un'epoca e un luogo lontani da noi, l'America degli anni '50. Vi presentiamo
                  il nostro nuovissimo AMERICAN DINER. Il primo diner multipiano in Sardegna.
                </p>
              </div>
              <hr />
            </Col>
          </Row>
        </FadeInSection>

        <FadeInSection delay={0.2}>
          <Row>
            <Col xs={12} md={12}>
              <div className="nons">
                <img src="./img/American/panino.png" alt="" id="imgdinner" />
                <h2 style={{ color: "#278280" }}>I nostri Hamburger</h2>
                <br />
                <p>* * * * *</p>
                <br />
                <p id="paragrafodinner">
                  Sapevi che i nostri hamburger, realizzati con carne di manzo 100% pura, sono lavorati per essere
                  ancora più succosi? Ogni giorno prepariamo circa 1000 hamburger, preferendo una cottura media, senza
                  precotture o trattamenti preliminari, tutto fatto al momento! 1950 American Diner si dedica
                  quotidianamente a offrirvi il massimo della qualità, scegliendo con cura le carni e approvvigionandosi
                  esclusivamente da produttori locali, per sostenere una rete territoriale che valorizza la freschezza e
                  il benessere degli animali.
                </p>
              </div>
            </Col>
            <hr></hr>
          </Row>
        </FadeInSection>

        <FadeInSection delay={0.3}>
          <Row>
            <Col xs={12} md={12}>
              <div className="nons">
                <img src="./img/American/costine.png" alt="" id="imgdinner" />
                <h2 style={{ color: "#278280" }}>Non solo Hamburger</h2>
                <br />
                <p>* * * * *</p>
                <br />
                <p id="paragrafodinner">
                  Scopri un mondo di sapori al American Diner! Dai croccanti Onion Rings alle succulente Chicken Wings e
                  Chicken Pop Corn, abbiamo piatti per tutti i gusti. L'Avocado Toast leggero e l'Hamburger con Contorno
                  saporito sono solo l'inizio. Non perderti la Bisteccona di Manzo e le BBQ Ribs, perfette con le
                  patatine fritte. Il nostro Salmon Grill e la Tagliata Rucola e Grana sono un'esperienza culinaria da
                  non perdere. Vieni a provare le nostre specialità, dall'Hamburger al California Grill, e lasciati
                  tentare dal Roasted Pecorino. Ti aspettiamo per un viaggio gastronomico indimenticabile!
                </p>
              </div>
            </Col>
            <hr></hr>
          </Row>
        </FadeInSection>

        <FadeInSection delay={0.4}>
          <Row>
            <Col xs={12} md={12}>
              <div className="nons">
                <img src="./img/American/noglutine.png" alt="" id="imgdinner" />
                <h2 style={{ color: "#278280" }}>Cucina Gluten Free</h2>
                <br />
                <p>* * * * *</p>
                <br />
                <p id="paragrafodinner">
                  Siamo lieti di offrire anche opzioni per chi segue una dieta senza glutine! Da Scattu American Diner,
                  comprendiamo l'importanza di fornire piatti adatti a tutti i nostri ospiti. Abbiamo dedicato cura e
                  attenzione per creare una selezione di delizie senza glutine, mantenendo intatti i sapori autentici
                  che caratterizzano la nostra cucina. Dalle nostre pietanze classiche come pizze e burgers fino alle
                  opzioni più sostanziose come la Bisteccona di Manzo e le BBQ Ribs. Tutto è stato pensato per
                  soddisfare i gusti e le esigenze di ogni commensale. Vieni a gustare la nostra cucina senza glutine e
                  scopri un'esperienza culinaria che non conosce compromessi!
                </p>
              </div>
              <hr></hr>
            </Col>
          </Row>
        </FadeInSection>

        <FadeInSection delay={0.5}>
          <Row>
            <Col xs={12} className="text-center">
              <Link to="/menu">
                <Button variant="success">Vai al Menu</Button>
              </Link>
            </Col>
          </Row>
        </FadeInSection>

        <br></br>
        <br></br>
        <br></br>

        <FadeInSection delay={0.6}>
          <Carousel />
        </FadeInSection>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
