import React, { useRef, useState } from "react";
import "./BigVideo.css";

function SmallVideo() {
  const videoRef = useRef(null);
  const [volume, setVolume] = useState(1); // Volume iniziale impostato a 1 (massimo)

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  return (
    <div className="small-video-container">
      <video autoPlay loop playsInline className="video" id="videoo" ref={videoRef}>
        <source src={process.env.PUBLIC_URL + "/img/video/dinnerxs.mp4"} type="video/mp4" />
        Il tuo browser non supporta il tag video.
      </video>
    </div>
  );
}

export default SmallVideo;
