import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop"; // Importa il componente ScrollToTop
import Home from "./components/Home/Home.jsx";
import Candidate from "./components/server/candidate/Candidate.jsx";
import Gallery from "./components/gallery/Gallery.jsx";
import Homedinner from "./components/americandinner/Homedinner.jsx";
import Menu from "./components/menu/Menu.jsx";
import Admin from "./components/server/login/Login.jsx";
/* import Register from "./components/server/register/Register.jsx"; */
import Choose from "./components/server/scelta/Choose.jsx";
import Importplate from "./components/server/importplate/Importplate.jsx";
import Importphoto from "./components/server/importphoto/Importphoto.jsx";
import Fuori from "./components/Fuorimenu/Fuorimenu.jsx";
import Homeapp from "./components/appartment/home/Homeap.jsx";

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Aggiungi ScrollToTop qui */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/candidati" element={<Candidate />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/diner" element={<Homedinner />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/choose" element={<Choose />} />
        <Route path="/importplate" element={<Importplate />} />
        <Route path="/importphoto" element={<Importphoto />} />
        <Route path="/fuorimenu" element={<Fuori />} />
        <Route path="/homeap" element={<Homeapp />} />
        {/* <Route path="/register" element={<Register />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
