import Carousel from "react-bootstrap/Carousel";

function DarkVariantExample() {
  return (
    <Carousel
      id="carosel"
      data-bs-theme="dark"
      style={{ height: "432px", objectFit: "cover", overflow: "hidden" }}
      controls={false}
      indicators={false}
      interval={3000}
      pause={false}
    >
      <Carousel.Item>
        <img className="d-block w-100" src="img/appartamenti/(1).jpg" alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src="img/appartamenti/(2).jpg" alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src="img/appartamenti/(3).jpg" alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src="img/appartamenti/(4).jpg" alt="Fourth slide" />
      </Carousel.Item>
    </Carousel>
  );
}

export default DarkVariantExample;
