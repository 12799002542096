import React, { useState, useEffect } from "react";
import { Container, Card, Modal, Button } from "react-bootstrap";
import Navbar1 from "../Navbar3/Navbar";
import Footer2 from "../footer/Footer";
import "./Gallery.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FadeInSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 3, delay }}
    >
      {children}
    </motion.div>
  );
};

function Gallery() {
  const [data, setData] = useState([]);
  const [loadingError, setLoadingError] = useState({ loading: true, error: null });
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("scattu/import.php");
        if (!response.ok) {
          throw new Error("Errore nel caricamento dei dati");
        }
        const jsonData = await response.json();
        const dataWithImageURL = jsonData.map((item) => ({
          ...item,
          image_url: `/scattu/img/${item.file}`,
        }));
        setData(dataWithImageURL);
        setLoadingError({ loading: false, error: null });
      } catch (error) {
        setLoadingError({ loading: false, error: error.message });
      }
    };
    fetchData();
  }, []);

  const handleShowModal = (item) => setSelectedItem(item);
  const handleCloseModal = () => setSelectedItem(null);

  return (
    <>
      <Navbar1 />
      <Container fluid style={{ marginBottom: "60px" }}>
        <div className="loghi" style={{ marginTop: "50px" }}>
          <Link to="/">
            <img src="./img/logo/logoscattu.png" alt="" style={{ width: "80px" }} />
          </Link>
          <Link to="/diner">
            <img src="./img/logo/burger.png" alt="" style={{ width: "80px" }} />
          </Link>
          <Link to="/homeap">
            <img src="./img/logo/app.png" alt="" style={{ width: "100px" }} />
          </Link>
        </div>

        <div className="mb-5 mt-5" />
        {loadingError.loading && <p>Caricamento...</p>}
        {loadingError.error && <p>{loadingError.error}</p>}
        {!loadingError.loading && !loadingError.error && (
          <div className="gallery-row">
            {data.map((item, index) => (
              <FadeInSection key={item.id} delay={index * 0.1}>
                <Card onClick={() => handleShowModal(item)} id="cardmanuale" className="card-gallery">
                  <Card.Img variant="top" src={item.image_url} className="card-img-top" alt="card" />
                  <div className="overlay"></div>
                </Card>
              </FadeInSection>
            ))}
          </div>
        )}
        <Modal show={!!selectedItem} onHide={handleCloseModal} centered>
          <Modal.Header closeButton />
          <Modal.Body>
            {selectedItem && (
              <>
                <h3 style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}>{selectedItem.titolo}</h3>
                <br />
                <p style={{ textAlign: "center" }}>{selectedItem.descrizione}</p>
                <p style={{ textAlign: "center", fontStyle: "italic" }}>{selectedItem.traduzione}</p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              style={{ fontSize: "0.8rem", padding: "0.375rem 0.75rem" }}
            >
              Chiudi
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <Footer2 />
    </>
  );
}

export default Gallery;
