import React, { useRef, useState, useEffect } from "react";
import "./BigVideo.css"; // Importa il file CSS per lo stile del componente

function BigVideo() {
  const videoRef = useRef(null);
  const [volume, setVolume] = useState(1); // Volume iniziale impostato a 1 (massimo)

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  // Effetto per aggiornare il volume quando il componente viene montato
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }, [volume]);

  return (
    <div className="big-video-container">
      <video
        autoPlay
        loop
        playsInline
        className="video"
        id="videoo"
        ref={videoRef}
        muted={false} // Assicurati che il video non sia muto
        controls // Aggiungi i controlli per permettere agli utenti mobili di regolare il volume direttamente
      >
        <source src={process.env.PUBLIC_URL + "/img/video/dinner.mp4"} type="video/mp4" />
        Il tuo browser non supporta il tag video.
      </video>
    </div>
  );
}

export default BigVideo;
