import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../Navbarap/Navbar";
import Carosello from "../caroselloap/Caroselloap";
import "./Homeap.css";
import Footer from "../../footer/Footer";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FadeInSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Debugging log
  console.log(`Component inView: ${inView}`);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 3, delay }} // Transition duration set to 3 seconds
    >
      {children}
    </motion.div>
  );
};

const Home = () => {
  return (
    <>
      <Navbar />
      <img src="/img/appartamenti/garden.jpg" alt="" id="immagineap"></img>

      <Container>
        <FadeInSection delay={0.1}>
          <h1 style={{ marginTop: "60px", marginBottom: "60px" }}> Cerchi Un alloggio?</h1>
        </FadeInSection>
        <FadeInSection delay={0.2}>
          <p style={{ textAlign: "center", display: "flex", marginInline: "auto", marginBottom: "60px" }}>
            "Benvenuti in un'oasi di comfort e stile, dove ogni angolo degli appartamenti in affitto riflette
            un'attenzione meticolosa per i dettagli."
          </p>
        </FadeInSection>
        <FadeInSection delay={0.3}>
          <Carosello></Carosello>
        </FadeInSection>
        <hr></hr>
        <FadeInSection delay={0.4}>
          <Row id="rigacentrale">
            <Col xs="12" md="12" lg="6" id="appartamenti">
              <h2 style={{ marginBottom: "20px", textAlign: "center" }} id="titolino">
                I nostri Appartamenti
              </h2>
              <p style={{ textAlign: "center" }} id="bigparagrafo">
                Da Scattu, ti attendono 12 spaziosi appartamenti circondati da un incantevole giardino, combinando la
                bellezza naturale con il lusso moderno. Ogni unità offre eleganza e funzionalità, con cucine
                completamente attrezzate, salotti accoglienti e wifi veloce gratuito. Goditi il comfort dell'aria
                condizionata nelle calde estati e il tepore accogliente durante i mesi più freddi. Inizia le tue
                giornate con caffè appena fatto grazie alle macchine del caffè in ogni appartamento. Con lavatrici e
                verandine esterne, tutto è pensato per il massimo comfort. Inoltre, offriamo servizio lavanderia per
                rendere il tuo soggiorno ancora più piacevole. Scopri la tua oasi di tranquillità e lusso a Scattu, dove
                ogni giorno è un'avventura da ricordare.
              </p>
            </Col>
            <Col xs="12" md="12" lg="6" id="colap">
              <img src="/img/appartamenti/camera.jpeg" alt="" style={{ marginTop: "-12px" }} id="immagineapp"></img>
            </Col>
          </Row>
        </FadeInSection>
        <FadeInSection delay={0.5}>
          <Row>
            <div
              className="div"
              style={{ marginTop: "15px", width: "100%", marginBottom: "80px", background: "#e7e7e74f" }}
            >
              <h1 id="book">Prenota subito </h1>
            </div>
          </Row>
        </FadeInSection>
        <FadeInSection delay={0.6}>
          <Row style={{ width: "-26px" }}>
            <div className="icona">
              <a href="https://wa.me/3293492200" target="_blank">
                <img
                  src="/img/logo/whatsapp.png"
                  alt=""
                  style={{ width: "51px", marginRight: "24px" }}
                  id="logo11"
                ></img>
              </a>
              <a href="https://www.booking.com/hotel/it/domos-de-ammentos.es.html " alt="">
                <img src="/img/logo/book.png" alt="" style={{ width: "204px" }} id="logo12"></img>
              </a>
              <a href="tel:3293492200">
                <img
                  src="/img/logo/telefono.png"
                  alt=""
                  style={{ marginLeft: "14px", width: "30px" }}
                  id="logo13"
                ></img>
              </a>
              <hr></hr>
            </div>
          </Row>
        </FadeInSection>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
