import React, { useState, useEffect } from "react";
import "./Video.css";

function Video() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 400);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 450);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <img src="/img/restaurant/sala.jpg" alt="Restaurant Sala" id="home" style={{ width: "100%" }} />
    </>
  );
}

export default Video;
