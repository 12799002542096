import React, { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import Navbar from "../../Navbar3/Navbar";
import Footer from "../../footer/Footer";
import { Link } from "react-router-dom";
function FormExample() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const schema = yup.object().shape({
    firstName: yup.string().required("Il nome è richiesto"),
    lastName: yup.string().required("Il cognome è richiesto"),
    email: yup.string().required("Il nome utente è richiesto"),
    city: yup.string().required("La città è richiesta"),
    state: yup.string().required("Lo stato è richiesto"),
    cellulare: yup.string().required("Il numero di cellulare è richiesto"),
    ruolo: yup.string().required("Il ruolo è richiesto"),
    file: yup.mixed().required("Il file è richiesto"),
    terms: yup.bool().oneOf([true], "I termini devono essere accettati").required("I termini devono essere accettati"),
  });

  const submitForm = async (values) => {
    try {
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("cellulare", values.cellulare);
      formData.append("ruolo", values.ruolo);
      formData.append("file", values.file); // Aggiungi il file al FormData

      const response = await fetch("scattu/", {
        method: "POST",
        body: formData, // Passa il FormData come corpo della richiesta
      });

      if (response.ok) {
        setFormSubmitted(true);
      } else {
        console.error("Impossibile inviare il modulo:", response.statusText);
      }
    } catch (error) {
      console.error("Impossibile inviare il modulo:", error);
    }
  };

  return (
    <>
      <Navbar />
      <Container className="form-container">
        <div className="loghi">
          <div className="loghi" style={{ marginTop: "0px" }}>
            <Link to="/">
              <img src="./img/logo/logoscattu.png" alt="" style={{ width: "80px" }} />
            </Link>
            <Link to="/dinner">
              <img src="./img/logo/burger.png" alt="" style={{ width: "80px" }} />
            </Link>
            <Link to="/homeap">
              <img src="./img/logo/app.png" alt="" style={{ width: "100px" }} />
            </Link>
          </div>
        </div>

        {formSubmitted ? (
          <div className="text-center">
            <h3>Modulo Inviato con Successo!</h3>
            <p>Abbiamo ricevuto le tue informazioni.</p>
          </div>
        ) : (
          <div>
            <div className="logocandidati d-flex justify-content-center">
              <img
                src="/img/iknos2.png"
                alt=""
                style={{ width: "108px", marginTop: "34px", marginBottom: "20px" }}
                id="logocandidati"
              />
            </div>
            <h2 className="form-title" style={{ textAlign: "center", marginBottom: "50px" }}>
              Modulo di Candidatura
            </h2>
            <Formik
              validationSchema={schema}
              onSubmit={(values) => {
                submitForm(values);
              }}
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                city: "",
                state: "",
                cellulare: "",
                ruolo: "",
                file: null,
                terms: false,
              }}
            >
              {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationFormik101" className="form-group">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        placeholder="Inserisci nome"
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        isValid={touched.firstName && !errors.firstName}
                        isInvalid={touched.firstName && !!errors.firstName}
                        className="custom-input"
                      />
                      <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationFormik102" className="form-group">
                      <Form.Label>Cognome</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Inserisci cognome"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        isValid={touched.lastName && !errors.lastName}
                        isInvalid={touched.lastName && !!errors.lastName}
                        className="custom-input"
                      />
                      <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationFormikemail2" className="form-group">
                      <Form.Label>Email</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="text"
                          placeholder="Inserisci email"
                          aria-describedby="inputGroupPrepend"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          isInvalid={touched.email && !!errors.email}
                          className="custom-input"
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationFormik103" className="form-group">
                      <Form.Label>Città</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Città"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        isValid={touched.city && !errors.city}
                        isInvalid={touched.city && !!errors.city}
                        className="custom-input"
                      />
                      <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationFormik104" className="form-group">
                      <Form.Label>Stato</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Stato"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        isValid={touched.state && !errors.state}
                        isInvalid={touched.state && !!errors.state}
                        className="custom-input"
                      />
                      <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationFormik105" className="form-group">
                      <Form.Label>Cellulare</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Cellulare"
                        name="cellulare"
                        value={values.cellulare}
                        onChange={handleChange}
                        isValid={touched.cellulare && !errors.cellulare}
                        isInvalid={touched.cellulare && !!errors.cellulare}
                        className="custom-input"
                      />
                      <Form.Control.Feedback type="invalid">{errors.cellulare}</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="4" controlId="validationFormik106" className="form-group">
                      <Form.Label className="mt-2">Per quale ruolo vuoi candidarti?</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ruolo"
                        name="ruolo"
                        value={values.ruolo}
                        onChange={handleChange}
                        isValid={touched.ruolo && !errors.ruolo}
                        isInvalid={touched.ruolo && !!errors.ruolo}
                        className="custom-input"
                      />
                      <Form.Control.Feedback type="invalid">{errors.ruolo}</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Form.Group className="form-group mt-2">
                    <Form.Label>File</Form.Label>
                    <Form.Control
                      type="file"
                      required
                      name="file"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]); // Imposta il valore del campo file
                      }}
                      isInvalid={!!errors.file}
                      className="custom-file-input"
                      style={{ marginBottom: "50PX" }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group" style={{ marginBottom: "50px" }}>
                    <Form.Check
                      required
                      name="terms"
                      label="Accetto i termini e le condizioni"
                      onChange={handleChange}
                      isInvalid={!!errors.terms}
                      feedback={errors.terms}
                      feedbackType="invalid"
                      id="validationFormik106"
                      feedbackTooltip
                      className="mt-2 mb-5"
                    />
                  </Form.Group>

                  <p className="feedback-text" style={{ marginInline: "auto", textAlign: "center" }}>
                    <b>Le candidature sprovviste di CV non verranno prese in considerazione.</b>
                  </p>

                  <Button
                    type="submit"
                    className="custom-button mt-5 mb-5"
                    style={{ textAlign: "center", marginInline: "auto" }}
                    id="btncandidati"
                  >
                    Invia la tua Candidatura
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
}

export default FormExample;
