import React from "react";
import { Button, Container } from "react-bootstrap";
import Navbar from "../Navbar3/Navbar.jsx";
import ristorante from "./ristorante.pdf";
import pizza from "./pizza.pdf";
import american from "./american.pdf";
import { Row, Col } from "react-bootstrap";
import "./Menu.css";
import Footer2 from "../footer/Footer";
import { Link } from "react-router-dom";
function Menu() {
  return (
    <>
      <Navbar />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Container style={{ marginBottom: "100px" }}>
        <div className="loghi" style={{ marginTop: "0px" }}>
          <Link to="/">
            <img src="./img/logo/logoscattu.png" alt="" style={{ width: "80px" }} />
          </Link>
          <Link to="/diner">
            <img src="./img/logo/burger.png" alt="" style={{ width: "80px" }} />
          </Link>
          <Link to="/homeap">
            <img src="./img/logo/app.png" alt="" style={{ width: "100px" }} />
          </Link>
        </div>
        <hr />
        <Row style={{ marginTop: "40px", marginBottom: "40px" }}>
          <Col xs={12} md={4} className="button-container">
            <Button as="a" href={ristorante} target="_blank" rel="noreferrer" className="custom-button" id="fuorimenu1">
              Ristorante
            </Button>
          </Col>
          <Col xs={12} md={4} className="button-container">
            <Button as="a" href={pizza} target="_blank" rel="noreferrer" className="custom-button" id="fuorimenu1">
              Pizzeria
            </Button>
          </Col>
          <Col xs={12} md={4} className="button-container">
            <Button as="a" href={american} target="_blank" rel="noreferrer" className="custom-button" id="fuorimenu1">
              American Diner
            </Button>
          </Col>
          <Col xs={12} md={12} className="button-container">
            <Link to="/fuorimenu" className="custom-link" style={{ fontFamily: "cormourant" }}>
              <Button className="custom-button mt-4" id="fuorimenu1">
                Fuori Menù
              </Button>
            </Link>
          </Col>
        </Row>
        <hr />
      </Container>
      <Footer2 />
    </>
  );
}

export default Menu;
